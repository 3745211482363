import React,{ useState,useContext,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component'
import { useMediaQuery } from 'react-responsive';

import Loading from 'components/shared/loading';

import { ButtonsType } from 'shared/buttonsType';
import { trackClick } from 'hooks/tracking';
import Button from 'components/shared/button';

import HomeBusesContext from 'hooks/homeBuses/homeBusesContext';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const SeoContent = loadable(() => import(
    /* webpackChunkName: 'SeoContent' */
    'components/buses/sections/seoContent'
), { fallback: <Loading /> });

const WaysToBuy = loadable(() => import(
    /* webpackChunkName: 'WaysToBuy' */
    'components/buses/sections/waysToBuy'
), { fallback: <Loading /> });

const InformativeContent = loadable(() => import(
    /* webpackChunkName: 'InformativeContent' */
    'components/buses/sections/informativeContent'
), { fallback: <Loading /> });

const SeoTextBanners = loadable(() => import(
    /* webpackChunkName: 'SeoTextBanners' */
    'components/shared/seoTextBanners'
), { fallback: <Loading /> });

export default () => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('sections');
    const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.sm})` });
    const homeBusesContent = useContext(HomeBusesContext);
    const enableStrapi = process.env.FT_ENABLE_STRAPI_CMS;
    const [ data,setData ] = useState(null);

    useEffect(() => {
        if (enableStrapi) {
            const {seoTextBanner} = homeBusesContent;
            setData(seoTextBanner);
        }
    }, [homeBusesContent]);

    return (
        <div className={classes.container}>
            {
                isMobile
                    ? <>
                        <Button
                            customClasses={classes.buttonColors}
                            buttonsType={ButtonsType.Link}
                            to={t('dealsLink')}
                            onClick={() => trackClick('Boton Promociones Y Beneficios Mobile')}
                        >
                            {t('deals')}
                        </Button>
                        <div className={classes.generalConditions}>
                            <a href={t('generalConditionsLink')}>
                                <p data-tracking='Boton Condiciones Generales Mobile'>
                                    {t('generalConditions')}
                                </p>
                            </a>
                        </div>
                    </>
                    : <>
                        <WaysToBuy />
                        <InformativeContent />
                    </>
            }
            <SeoContent />
            <SeoTextBanners seoTextBox={data} endpoint='Buses/ObtenerTextosSEOHome' feature={enableStrapi} />
        </div>
    );
}