import { useState, useEffect } from 'react';

import useLocalStorage from 'hooks/localStorage';

const useCashPurchase = () => {
    const { hasLocalStorage, getValueFromLocalStorage } = useLocalStorage();
    const NAME_KEY_STORAGE = 'cashPurchase';
    const [showCashPurchase, setShowCashPurchase] = useState(true);

    useEffect(() => {
        const value = getValueFromLocalStorage(NAME_KEY_STORAGE, true);
        setShowCashPurchase(value);
    }, []);

    const hideCashPurchase = () => {
        if (!hasLocalStorage()) return;
        let cashPurchase = getValueFromLocalStorage(NAME_KEY_STORAGE, true);
        cashPurchase = false;
        localStorage.setItem(NAME_KEY_STORAGE, JSON.stringify(cashPurchase));
        setShowCashPurchase(cashPurchase);
    }

    return { showCashPurchase, hideCashPurchase };
}

export default useCashPurchase;