// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7eb00bccb09b54d566dd948b5c2a444c-scss{height:100%;width:100%;border-radius:10px;background-position:center;background-size:cover;background-repeat:no-repeat}\n", ""]);
// Exports
exports.locals = {
	"banner": "_7eb00bccb09b54d566dd948b5c2a444c-scss"
};
module.exports = exports;
