import React, { useEffect } from 'react';

import Loading from 'components/shared/loading';
import Image from 'components/shared/image';

import { useTracking } from 'hooks/home/tracking';
import { trackClick } from 'hooks/tracking';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ banner }) => {
  useStyles(classes);
  const { bannerClick, bannerImpression } = useTracking();

  useEffect(() => {
    bannerImpression(banner);
  }, [])

  const handleClick = banner => {
    bannerClick(banner)
    trackClick('Imagen destacada')
  };

  return (
    banner
      ? <a href={banner.link} onClick={() => handleClick(banner)} target="_blank">
          <Image 
            image={banner.image} 
            alt='main banner' 
            target={banner.target} 
            customClasses={classes.banner} 
          />
        </a>
      : <Loading height={'314px'} width={'314px'} />
  );
}