import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Skeleton, Modal } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import BusesSearchBox from 'components/buses/searchBox';
import Slider from 'components/shared/slider';
import Sections from 'components/buses/sections';
import Image from 'components/shared/image';
import Banner from 'components/shared/banner';
import Icon from 'components/shared/icon';
import CustomHead from 'components/shared/customHead';
import NoSSR from 'components/shared/noSSR';
import Loading from 'components/shared/loading';
import Button from 'components/shared/button';
import CustomDialog from 'components/shared/customDialog';

import { useApplication } from 'hooks/application';
import { useDefaultCity } from 'hooks/howToBuy';
import { useTracking } from 'hooks/home/tracking';
import useCashPurchase from 'hooks/cashPurchase';

import HomeBusesContext from 'hooks/homeBuses/homeBusesContext';

import urls from 'shared/urls';
import googleAnalytics from 'shared/metrics/google-analytics';
import breakpoints from 'shared/breakpoints';
import cookie from 'shared/cookie';
import getUser from 'shared/authenticatedUser';
import { trackClick } from 'hooks/tracking';
import { ButtonsType } from 'shared/buttonsType';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

import moment from 'moment';

//TODO: THIS IS TEMPORAL UNTIL WE HAVE A BETTER WAY TO HANDLE THIS
const LIMIT_DATE_TO_SHOW_MODAL = '2024-05-20';

export default () => {
  useStyles(classes, globalStyles);
  const { t } = useTranslation('home');
  const defaultCity = useDefaultCity();
  const { authenticated } = getUser();
  const { country } = useApplication();
  const { showCashPurchase, hideCashPurchase } = useCashPurchase();
  const isDestkop = useMediaQuery({ query: `(min-width:${breakpoints.md})` });
  const homeBusesContent = useContext(HomeBusesContext);

  useTracking();

  const [data, setData] = useState();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const countries = ['Argentina'];

  useEffect(() => {
    cookie.set('source', 'legacy', 1, '.plataforma10.com.ar');
  }, []);

  useEffect(() => {
    const shouldShowMobileModal = countries.includes(country.name);

    const email = cookie.get('email');
    if (email) {
      googleAnalytics.TrackForGA4({
        'useremail': email
      });
    }

    if (shouldShowMobileModal) {
      
      const lastShownTime = moment(localStorage.getItem('modalLastShownTime'));
      const currentTime = moment();
      const limitDateToShowModal = moment(LIMIT_DATE_TO_SHOW_MODAL);
      const hasADifferentDay = lastShownTime.day() !== currentTime.day();
      const isLessThanLimitDate = limitDateToShowModal.diff(currentTime, 'days') > 0;

      if (isLessThanLimitDate) {
        if (!lastShownTime || hasADifferentDay) {
          setShowMobileModal(true);
          localStorage.setItem('modalLastShownTime', currentTime.toString());
        }
      }
    }
  }, [country.name]);
  
  useEffect(() => {
    const content = homeBusesContent?.bannersSliderYMetadata;
    setData(content);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [homeBusesContent]);


  useEffect(() => {
    setTimeout(1000);
    setReady(true);
  }, [country]);

  const onDialogClose = () => window.location = `/${t('myPurchasesLink')}`;

  const handleReturnButton = () => {
		if (!authenticated) {
			cookie.deleteAll();
			setShowDialog(true);
			trackClick('Boton devolucion pasaje home mobile');
		} else {
			onDialogClose();
		}
	}

  return (
    <div className={classes.homeContainer}>
      {data && <CustomHead metadata={data.metadata} />}
      <div className={classes.search}>
        <div className={classes.searchbox}>
          {ready ? (
            <BusesSearchBox />
          ) : (
            <Skeleton active />
          )}
        </div>
        {!isDestkop  &&
        <div className={classes.ReturnTicketContainer}>
            <Button 
                className={classes.customButton} 
                onClick={handleReturnButton}
            >
              { t('returnTicket') }  
            </Button> 	
            <Button 
                className={classes.customButton} 
                buttonsType={ButtonsType.ExternalLink}
                to='https://p10-cdn.s3.amazonaws.com/p10botondearrepentimiento.pdf'
            >
              { t('repentanceButton') }
              </Button> 
              </div>
              }
        {data && (
          <div className={classes.imagesContainer}>
            {isDestkop && (
              <div className={globalStyles.plr10}>
                <Banner banner={data.banner} />
              </div>
            )}
            {loading ? (
              <Loading />
            ) : (
              data.slides.length > 0 && (
                <div className={globalStyles.flexCenter}>
                  <Slider
                    slides={data.slides}
                    customClasses={{
                      button: classes.button,
                      navigation: classes.navigation,
                    }}
                  >
                    {data.slides.map((slide) => {
                      const {
                        link,
                        image: { alt, title, image, id },
                      } = slide;

                      const randomId = Math.random().toString(16).slice(2);

                      return link ? (
                        <a href={link} target="_blank" key={randomId}>
                          {loading ? (
                            <Loading />
                          ) : (
                            <Image
                              image={image}
                              alt={alt}
                              title={title}
                              customClasses={classes.slide}
                            />
                          )}
                        </a>
                      ) : (
                        loading ? (
                          <Loading />
                        ) : (
                          <Image
                            image={image}
                            alt={alt}
                            title={title}
                            customClasses={classes.slide}
                            key={randomId}
                          />
                        )
                      );
                    })}
                  </Slider>
                </div>
              )
            )}
          </div>
        )}
        {loading ? (
          <Loading />
        ) : (
          showCashPurchase && country.url === 'ar' && defaultCity && (
            <div
              className={classes.cash}
              onClick={() =>
                googleAnalytics.trackEvent(
                  'trackEvent',
                  'Tracking Click',
                  'botón Comprá en efectivo'
                )
              }
            >
              <span onClick={hideCashPurchase}>
                <Icon classes={classes.icon} name="times-circle" />
              </span>
              <Link
                className={classes.link}
                to={`/${t('personalPurchaseLink')}/${defaultCity.id
                  }/${urls.toSEOFriendly(defaultCity.name)}`}
              >
                ¡{t('buy')}!
              </Link>
            </div>
          )
        )}
        {
				showDialog &&
				<CustomDialog onClose={onDialogClose} title={t('attention')}>
					<div className={globalStyles.flexColumn}>
						<span> {t('returnLoginMessage')} </span>
					</div>
				</CustomDialog>
			}
      </div>
        <Modal
          centered
          className={classes.modalPromo}
          bodyStyle={{ padding: 'unset', display: 'flex' }}
          open={showMobileModal}
          onCancel={() => setShowMobileModal(false)}
          footer={null}
          closeIcon={<CloseCircleFilled className={classes.IconCloseCircleFilled} />}
          >
          <img className={classes.bannerMobile} src='https://p10-cdn.s3.amazonaws.com/public/images/home/pop-up-FINAL.jpg' alt='Promociones'/>
        </Modal>
      <NoSSR>
        <div className={globalStyles.flex}>
          <Sections />
        </div>
      </NoSSR>
    </div>
  );
};
